<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-edit"
        @click="handleCreate()"
        >添加</el-button
      >
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      fit
      highlight-current-row
    >
      <el-table-column align="center" label="ID" width="60">
        <template slot-scope="scope">
          <!-- {{ scope.$index }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.school_name }}
        </template>
      </el-table-column>
      <el-table-column label="地址" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.address }}
        </template>
      </el-table-column>
      <el-table-column label="配送方式" min-width="110" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.send_type.indexOf(1) > -1">配送服务</div>
          <div v-if="scope.row.send_type.indexOf(2) > -1">智慧餐柜</div>
        </template>
      </el-table-column>
      <el-table-column label="支付方式" min-width="110" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.pay_method === 'weixin'">微信支付</div>
          <div v-else-if="scope.row.pay_method === 'ccb'">建行支付</div>
          <div v-else-if="scope.row.pay_method === 'sp'">服务商支付</div>
        </template>
      </el-table-column>
      <el-table-column label="客服电话" min-width="110" align="center">
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.contact" :key="index">
            {{ item }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="急速退款限制(分钟)" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.quick_refund_limit }}
        </template>
      </el-table-column>
      <el-table-column label="赔付比率(%)" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.compensation_ratio*100 }}
        </template>
      </el-table-column>
      <el-table-column label="配餐时长(分钟)" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.meal_timeout_limit }}
        </template>
      </el-table-column>
      <el-table-column label="配送时长(分钟)" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.shipping_timeout_limit }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        fixed="right"
        align="center"
        width="230"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button type="danger" size="mini" @click="handleDel(scope.row)">删除</el-button>
          <el-button type="warning" size="mini" @click="handleSetOprationPassword(scope.row)" v-show="user.user.type === 'admin'">设置操作密码</el-button>
          <el-button type="warning" size="mini" @click="handlePickupLedgerEdit(scope.row)" v-if="user.user.type === 'admin' || user.permissions.indexOf('pickup') !== -1">设置代取分账</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[5, 10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="70%"
    >
      <el-form
        ref="form"
        :model="form"
        label-position="left"
        label-width="150px"
      >
        <el-form-item label="学校名称" prop="name">
          <el-input v-model="form.school_name" placeholder="请输入学校名称" />
        </el-form-item>
        <el-form-item label="学校地址" prop="name">
          <el-input v-model="form.address" placeholder="请输入学校地址" />
        </el-form-item>
        <el-form-item label="配送方式" prop="name">
          <el-checkbox-group v-model="form.send_type" size="small">
            <el-checkbox :label="1" :disabled="true" size="mini"
              >配送服务</el-checkbox
            >
            <el-checkbox :label="2" size="mini">智慧餐柜</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="支付方式" prop="name">
          <el-radio-group v-model="form.pay_method">
            <el-radio label="weixin">微信支付</el-radio>
            <el-radio label="ccb">建行支付</el-radio>
            <el-radio label="sp">服务商支付</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.pay_method=='sp'" label="特约商户号" prop="name">
          <el-input v-model="form.sub_mch_id" placeholder="请输入特约商户号"/>
        </el-form-item>
        <el-form-item v-if="form.pay_method=='sp'" label="分账角色">
          <el-checkbox-group v-model="form.ledger_role_ids">
            <el-checkbox v-for="item in schoolLedgerRoles" v-if="item.is_use" :key="item.id" :label="item.id">{{item.role_name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="客服电话">
          <el-tag
            :key="tag"
            v-for="tag in form.contact"
            closable
            :disable-transitions="false"
            @close="handleClose(tag)"
          >
            {{ tag }}
          </el-tag>
          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="showInput"
            >+ 添加</el-button
          >
        </el-form-item>
        <el-form-item label="急速退款限制(分钟)">
          <el-input-number v-model="form.quick_refund_limit" controls-position="right" :min="0" :max="99"></el-input-number>
        </el-form-item>
        <el-form-item label="赔付比率(%)">
          <el-input-number v-model="form.compensation_ratio" controls-position="right" :min="0" :max="100"></el-input-number>
        </el-form-item>
        <el-form-item label="投餐配送费">
          <el-input-number v-model="form.sideboard_send_fee" controls-position="right" :precision="2" :min="0"></el-input-number>
        </el-form-item>
        <el-form-item label="上楼配送费">
          <el-input-number v-model="form.upstairs_send_fee" controls-position="right" :precision="2" :min="0"></el-input-number>
        </el-form-item>
        <el-form-item label="上楼服务时间">
          <el-tag v-if="form.upstairs_service_time.length <= 0">休息</el-tag>
          <el-tag v-else :key="index" v-for="(item,index) in form.upstairs_service_time" closable @close="handleCloseTag(index)" style="margin-right: 3px">
            {{item[0]}} ~ {{item[1]}}
          </el-tag>
          <el-popover placement="right" v-model="showTimePicker">
            <p>
              <el-time-picker is-range size="small" v-model="timeArr" format="HH:mm" value-format="HH:mm"  range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围"></el-time-picker>
            </p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="showTimePicker = false">取消</el-button>
              <el-button type="primary" size="mini" @click="chooseTime">确定</el-button>
            </div>
            <el-button slot="reference" class="button-new-tag" size="small">+ 添加</el-button>
          </el-popover>
        </el-form-item>
        <el-form-item label="商家承担配送费状态">
          <el-switch v-model="form.shop_bear_send_fee_status" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="分拣员结算">
          <el-input v-model="form.distributeman_hourly_wage" type="number" min="0" placeholder="请输入时薪" style="margin-bottom: 10px;">
            <template slot="prepend">时薪</template>
            <template slot="append">元</template>
          </el-input>
          <el-input v-model="form.distributeman_sideboard_reward" type="number" min="0" placeholder="请输入每单投餐服务提成" style="margin-bottom: 10px;">
            <template slot="prepend">每单投餐服务提成</template>
            <template slot="append">元</template>
          </el-input>
          <el-input v-model="form.distributeman_upstairs_reward" type="number" min="0" placeholder="请输入每单上楼服务提成" style="margin-bottom: 10px;">
            <template slot="prepend">每单上楼服务提成</template>
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="配送员结算">
          <el-input v-model="form.sendman_hourly_wage" type="number" min="0" placeholder="请输入时薪" style="margin-bottom: 10px;">
            <template slot="prepend">时薪</template>
            <template slot="append">元</template>
          </el-input>
          <el-input v-model="form.sendman_sideboard_reward" type="number" min="0" placeholder="请输入每单投餐服务提成" style="margin-bottom: 10px;">
            <template slot="prepend">每单投餐服务提成</template>
            <template slot="append">元</template>
          </el-input>
          <el-input v-model="form.sendman_upstairs_reward" type="number" min="0" placeholder="请输入每单上楼服务提成" style="margin-bottom: 10px;">
            <template slot="prepend">每单上楼服务提成</template>
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="配餐时长(分钟)">
          <el-input-number v-model="form.meal_timeout_limit" controls-position="right" :min="0" :max="999"></el-input-number>
          <div style="margin-top:10px;font-size: 12px;color:red;line-height:16px;">
            <div>*接单到分拣的时长</div>
          </div>
        </el-form-item>
        <el-form-item label="配送时长(分钟)">
          <el-input-number v-model="form.shipping_timeout_limit" controls-position="right" :min="0" :max="999"></el-input-number>
          <div style="margin-top:10px;font-size: 12px;color:red;line-height:16px;">
            <div>*分拣到骑手取餐的时长</div>
          </div>
        </el-form-item>
        <el-form-item label="经纬度">
          <div v-if="form.lng">{{ form.lng }},{{ form.lat }}</div>
          <div v-else>请在地图上选点，然后点击“选择该位置”按钮获取经纬度</div>
          <div style="position: relative">
            <AMapPosition
              ref="map"
              @choosePosition="choosePosition"
              :height="300"
            ></AMapPosition>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <!-- 设置操作密码 -->
    <el-dialog title="设置操作密码" :visible.sync="setOperationPasswordVisible" width="60%" :close-on-click-modal="false">
      <el-form ref="setOperationPasswordForm" :rules="setOperationPasswordRules" :model="setOperationPasswordForm" label-width="180px">
        <el-form-item label="操作密码" prop="operation_password">
          <el-input v-model="setOperationPasswordForm.operation_password" placeholder="请输入操作密码" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="setOperationPasswordVisible = false">取消</el-button>
        <el-button type="primary" @click="setOperationPasswordSaveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
    <!-- 设置操作密码 -->

    <el-dialog :visible.sync="dialogVisiblePickupLedger" width="50%" :close-on-click-modal="false">
      <el-form ref="pickupLedgerForm" :model="pickupLedgerForm" label-position="right" label-width="150px">
        <div style="font-size:12px;color:red;margin-bottom:20px;">*注意：切换分账类型时，旧分账类型信息需保留，避免以前订单分账失败</div>
        <el-form-item label="分账类型">
          <el-radio-group v-model="pickupLedgerForm.ledger_type">
            <el-radio :label="1">微信分账</el-radio>
          </el-radio-group>
        </el-form-item>

        <template v-if="pickupLedgerForm.ledger_type === 1">
          <el-form-item label="特约商户号">
            <el-input v-model="pickupLedgerForm.sub_mch_id" placeholder="请输入特约商户号" />
          </el-form-item>

          <el-form-item label="微信分账角色">
            <el-checkbox-group v-model="pickupLedgerForm.ledger_role_ids">
              <el-checkbox v-for="item in schoolLedgerRoles" :key="item.id" :label="item.id">{{item.role_name}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </template>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblePickupLedger = false">取消</el-button>
        <el-button type="primary" @click="saveDataPickupLedger" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import AMapPosition from "@/components/AMapPosition";
import {mapGetters} from "vuex";

export default {
  components: {
    AMapPosition,
  },
  data() {
    return {
      list: null,
      total: null,
      btnLoading: false,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
      },
      dialogVisible: false,
      form: {
        id: "",
        school_name: "",
        address: "",
        lng: "",
        lat: "",
        send_type: [1],
        contact: [],
        pay_method: "sp",
        compensation_ratio:'',
        sub_mch_id: "",
        ledger_role_ids: [],
        quick_refund_limit: 0,
        sideboard_send_fee: 0,
        upstairs_send_fee: 0,
        upstairs_service_time: [],
        distributeman_hourly_wage: 0,
        distributeman_sideboard_reward: 0,
        distributeman_upstairs_reward: 0,
        sendman_hourly_wage: 0,
        sendman_sideboard_reward: 0,
        sendman_upstairs_reward: 0,
        meal_timeout_limit: 0,
        shipping_timeout_limit: 0,
        shop_bear_send_fee_status: 0,
      },
      inputVisible: false,
      inputValue: "",
      schoolLedgerRoles: [],
      // 上楼服务时间
      showTimePicker: false,
      timeArr: null,
      // 设置操作密码
      setOperationPasswordVisible: false,
      setOperationPasswordForm: {
        id: "",
        operation_password: '',
      },
      setOperationPasswordRules: {
        operation_password: [{ required: true, message: "操作密码不能为空", trigger: "change" }],
      },
      // 设置代取分账
      dialogVisiblePickupLedger: false,
      pickupLedgerForm: {
        school_id: "",
        ledger_type: 1,
        sub_mch_id: "",
        ledger_role_ids: []
      },
    };
  },
  created() {
    this.getList();
  },
  computed: {
    // 已选择的角色
    selectedRoleId: function() {
      var ledger_role_ids = []
      for(var i=0;i<this.form.ledger_role_ids.length;i++){
        for(var j=0;j<this.schoolLedgerRoles.length;j++){
          if(this.form.ledger_role_ids[i] === this.schoolLedgerRoles[j].id){
            ledger_role_ids.push(this.schoolLedgerRoles[j].id);
            break;
          }
        }
      }
      return ledger_role_ids;
    },

    selectedRoleIdLedger () {
      return ledger_role_ids => {
        let ids = []
        for(let i=0;i<ledger_role_ids.length;i++){
          if (this.schoolLedgerRoles.find((item) => item.id === ledger_role_ids[i])) {
            ids.push(ledger_role_ids[i]);
          }
        }
        return ids;
      }
    },

    ...mapGetters(["schools", "school_id","user"])
  },
  watch: {
    dialogVisible(newVal, oldVal) {
      // 编辑框一异隐藏，马上清除旧数据
      if (newVal === false) {
        this.form = {
          id: "",
          school_name: "",
          address: "",
          lng: "",
          lat: "",
          send_type: [1],
          contact: [],
          pay_method: "sp",
          compensation_ratio:'',
          sub_mch_id: "",
          ledger_role_ids: [],
          quick_refund_limit: 0,
          sideboard_send_fee: 0,
          upstairs_send_fee: 0,
          upstairs_service_time: [],
          distributeman_hourly_wage: 0,
          distributeman_sideboard_reward: 0,
          distributeman_upstairs_reward: 0,
          sendman_hourly_wage: 0,
          sendman_sideboard_reward: 0,
          sendman_upstairs_reward: 0,
          meal_timeout_limit: 0,
          shipping_timeout_limit: 0,
          shop_bear_send_fee_status: 0,
        };
        this.timeArr = null
      }
    },
    dialogVisiblePickupLedger(newVal, oldVal) {
      // 编辑框一异隐藏，马上清除旧数据
      if (newVal === false) {
        this.pickupLedgerForm = {
          school_id: "",
          ledger_type: 1,
          sub_mch_id: "",
          ledger_role_ids: []
        };
      }
    },
  },
  methods: {
    getList() {
      this.listLoading = true;
      request({
        url: "/api/backend/school/list",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    choosePosition(res) {
      console.log(res);
      this.form.lng = res.position.lng;
      this.form.lat = res.position.lat;
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleClose(tag) {
      this.form.contact.splice(this.form.contact.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.form.contact.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    handleCreate() {
      this.schoolLedgerRoles = [];
      this.dialogVisible = true;
    },
    async handleEdit(item) {
      // 操作密码
      const { password } = await this.$oprationPassword()
      await request({
        url: "/api/backend/school/checkOperationPassword",
        method: "get",
        params: {
          id: item.id,
          operation_password: password,
        },
      }).then(() => {

      }).catch(() => {
        return new Promise((resolve, reject) => {
          this.promise = { resolve, reject } // 将resolve 、reject暂存起来,方便调用
        })
      });

      request({
        url: "/api/backend/school/ledgerRoleList",
        method: "get",
        params: {
          page: 1,
          limit: 100000,
          school_id: item.id,
          ledger_type: 1,
          is_use: 1,
        }
      }).then(response => {
        this.schoolLedgerRoles = response.data.data;

        this.form = {
          id: item.id,
          school_name: item.school_name,
          address: item.address,
          lng: item.lng,
          lat: item.lat,
          send_type: item.send_type,
          contact: item.contact,
          compensation_ratio:item.compensation_ratio*100,
          pay_method: item.pay_method,
          sub_mch_id: item.sub_mch_id,
          ledger_role_ids: item.ledger_role_ids || [],
          quick_refund_limit: item.quick_refund_limit,
          sideboard_send_fee: item.sideboard_send_fee,
          upstairs_send_fee: item.upstairs_send_fee,
          upstairs_service_time: JSON.parse(JSON.stringify(item.upstairs_service_time)),
          distributeman_hourly_wage: item.distributeman_hourly_wage,
          distributeman_sideboard_reward: item.distributeman_sideboard_reward,
          distributeman_upstairs_reward: item.distributeman_upstairs_reward,
          sendman_hourly_wage: item.sendman_hourly_wage,
          sendman_sideboard_reward: item.sendman_sideboard_reward,
          sendman_upstairs_reward: item.sendman_upstairs_reward,
          meal_timeout_limit: item.meal_timeout_limit,
          shipping_timeout_limit: item.shipping_timeout_limit,
          shop_bear_send_fee_status: item.shop_bear_send_fee_status,
        };
        if (item.lng && item.lat) {
          this.$nextTick(() => {
            this.$refs["map"].setCenter(item.lng, item.lat);
          });
        }
        this.form.ledger_role_ids = this.selectedRoleId;
        this.dialogVisible = true;
      });
    },
    async handleDel(item) {
      // 操作密码
      const { password } = await this.$oprationPassword()
      await request({
        url: "/api/backend/school/checkOperationPassword",
        method: "get",
        params: {
          id: item.id,
          operation_password: password,
        },
      }).then(() => {

      }).catch(() => {
        return new Promise((resolve, reject) => {
          this.promise = { resolve, reject } // 将resolve 、reject暂存起来,方便调用
        })
      });

      this.$confirm("确定要删除学校吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        request({
          url: "/api/backend/school/del",
          method: "get",
          params: {
            id: item.id,
          },
        }).then(() => {
          // 删除最后一条数据时无数据问题
          this.list.length <= 1 ? this.listQuery.page-- : false;
          this.getList();
          this.$store.dispatch("GetSchools");
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
      });
    },
    saveData() {
      if (!this.form.school_name) {
        this.$message({
          type: "warning",
          message: "请输入学校名称",
        });
        return;
      }
      if (!this.form.address) {
        this.$message({
          type: "warning",
          message: "请输入学校地址",
        });
        return;
      }
      if (!this.form.lng || !this.form.lat) {
        this.$message({
          type: "warning",
          message: "请选择经纬度",
        });
        return;
      }
      if (this.form.pay_method == 'sp' && !this.form.sub_mch_id) {
        this.$message({
          type: "warning",
          message: "请输入特约商户号",
        });
        return;
      }
      if (this.form.quick_refund_limit === undefined) {
        this.$message({
          type: "warning",
          message: "请输入急速退款限制",
        });
        return;
      }
      if (this.form.compensation_ratio === undefined) {
        this.$message({
          type: "warning",
          message: "请输入赔付比率",
        });
        return;
      }
      if (this.form.sideboard_send_fee === undefined) {
        this.$message({
          type: "warning",
          message: "请输入投餐配送费",
        });
        return;
      }
      if (this.form.upstairs_send_fee === undefined) {
        this.$message({
          type: "warning",
          message: "请输入上楼配送费",
        });
        return;
      }
      if (this.form.distributeman_hourly_wage === '' || this.form.distributeman_hourly_wage < 0) {
        this.$message({
          type: "warning",
          message: "请输入分拣员时薪",
        });
        return;
      }
      if (this.form.distributeman_sideboard_reward === '' || this.form.distributeman_sideboard_reward < 0) {
        this.$message({
          type: "warning",
          message: "请输入分拣员每单投餐服务提成",
        });
        return;
      }
      if (this.form.distributeman_upstairs_reward === '' || this.form.distributeman_upstairs_reward < 0) {
        this.$message({
          type: "warning",
          message: "请输入分拣员每单上楼服务提成",
        });
        return;
      }
      if (this.form.sendman_hourly_wage === '' || this.form.sendman_hourly_wage < 0) {
        this.$message({
          type: "warning",
          message: "请输入配送员时薪",
        });
        return;
      }
      if (this.form.sendman_sideboard_reward === '' || this.form.sendman_sideboard_reward < 0) {
        this.$message({
          type: "warning",
          message: "请输入配送员每单投餐服务提成",
        });
        return;
      }
      if (this.form.sendman_upstairs_reward === '' || this.form.sendman_upstairs_reward < 0) {
        this.$message({
          type: "warning",
          message: "请输入配送员每单上楼服务提成",
        });
        return;
      }
      if (this.form.meal_timeout_limit === undefined) {
        this.$message({
          type: "warning",
          message: "请输入配餐时长",
        });
        return;
      }
      if (this.form.shipping_timeout_limit === undefined) {
        this.$message({
          type: "warning",
          message: "请输入配送时长",
        });
        return;
      }
      let formData = Object.assign({}, this.form);
      formData.compensation_ratio = formData.compensation_ratio/100
      this.btnLoading = true;
      request({
        url: "/api/backend/school/save",
        method: "post",
        data: formData,
      })
        .then(() => {
          this.btnLoading = false;
          this.dialogVisible = false;
          this.getList();
          this.$store.dispatch("GetSchools");
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },
    // 上楼服务时间
    handleCloseTag(index) {
      this.form.upstairs_service_time.splice(index, 1);
    },
    chooseTime() {
      if (this.timeArr) {
        if (this.timeArr[1] < this.timeArr[0]) {
          this.$message({
            type: "warning",
            message: "结束时间不能低于开始时间",
          });
          return;
        }
        this.form.upstairs_service_time.push(this.timeArr);
      }
      this.showTimePicker = false
    },
    // 设置操作密码
    resetForm() {
      this.setOperationPasswordForm = {
        id: "",
        operation_password: "",
      }
      this.btnLoading = false
    },
    handleSetOprationPassword(row) {
      this.resetForm()
      this.setOperationPasswordForm.id = row.id
      this.setOperationPasswordVisible = true
      this.$nextTick(() => {
        this.$refs["setOperationPasswordForm"].clearValidate() //清除校验结果
      })
    },
    setOperationPasswordSaveData() {
      this.$refs["setOperationPasswordForm"].validate((valid) => {
        if (valid) {
          this.btnLoading = true
          request({
            url: "/api/backend/school/setOperationPassword",
            method: "post",
            data: this.setOperationPasswordForm
          }).then(() => {
            this.setOperationPasswordVisible = false
            this.$message({
              type: "success",
              message: "操作成功"
            });
            this.getList();
          }).catch((err) => {
            console.log(err)
          }).finally(() => {
            this.btnLoading = false
          })
        }
      })
    },
    // 设置代取分账
    async handlePickupLedgerEdit(item) {
      // 操作密码
      const { password } = await this.$oprationPassword()
      await request({
        url: "/api/backend/school/checkOperationPassword",
        method: "get",
        params: {
          id: item.id,
          operation_password: password,
        }
      }).then(() => {

      }).catch(() => {
        return new Promise((resolve, reject) => {
          this.promise = { resolve, reject } // 将resolve 、reject暂存起来,方便调用
        })
      });

      request({
        url: "/api/backend/school/ledgerRoleList",
        method: "get",
        params: {
          page: 1,
          limit: 100000,
          school_id: item.id,
          ledger_type: 1,
          is_use: 1,
        }
      }).then(response => {
        this.schoolLedgerRoles = response.data.data;

        this.pickupLedgerForm = {
          school_id: item.id,
          ledger_type: item.school_pickup ? item.school_pickup.ledger_type : 1,
          sub_mch_id: item.school_pickup ? item.school_pickup.sub_mch_id : "",
          ledger_role_ids: item.school_pickup ? item.school_pickup.ledger_role_ids : []
        };
        this.pickupLedgerForm.ledger_role_ids = this.selectedRoleIdLedger(this.pickupLedgerForm.ledger_role_ids);
        this.dialogVisiblePickupLedger = true;
      });
    },
    saveDataPickupLedger() {
      if (this.pickupLedgerForm.ledger_type === 1 && !this.pickupLedgerForm.sub_mch_id) {
        this.$message({
          type: "warning",
          message: "特约商户号不能为空"
        });
        return;
      }

      this.btnLoading = true;
      request({
        url: "/api/backend/school/pickupLedgerSave",
        method: "post",
        data: this.pickupLedgerForm
      }).then(() => {
        this.dialogVisiblePickupLedger = false;
        this.getList();
        this.$message({
          type: "success",
          message: "操作成功!"
        });
      }).finally(() => {
        this.btnLoading = false;
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
.el-tag {
  margin-right: 10px;
}
.button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 140px;
  vertical-align: bottom;
}
</style>
